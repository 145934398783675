define('ui/services/growl', ['exports', 'ember', 'ui/utils/errors'], function (exports, _ember, _uiUtilsErrors) {
  exports['default'] = _ember['default'].Service.extend({
    init: function init() {
      $.jGrowl.defaults.pool = 6;
      $.jGrowl.defaults.closeTemplate = '<i class="icon icon-x"></i>';
      $.jGrowl.defaults.closerTemplate = '<div><button type="button" class="btn btn-info btn-xs btn-block">Dismiss All Notifications</button></div>';
    },

    raw: function raw(title, body, opt) {
      opt = opt || {};

      if (title) {
        opt.header = title;
      }

      return $.jGrowl(body, opt);
    },

    success: function success(title, body) {
      this.raw(title, body, {
        theme: 'success'
      });
    },

    message: function message(title, body) {
      this.raw(title, body, {
        theme: 'message'
      });
    },

    error: function error(title, body) {
      this.raw(title, body, {
        sticky: true,
        theme: 'error'
      });
    },

    fromError: function fromError(title, err) {
      var body = _uiUtilsErrors['default'].stringify(err);
      this.error(title, body);
    }
  });
});