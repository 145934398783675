define('ui/login/shibboleth-auth/route', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    shibbolethAuth: _ember['default'].inject.service(),
    intl: _ember['default'].inject.service(),
    queryParams: {
      shibbolethTest: {
        refreshModel: false
      },
      errCode: {
        refreshModel: false
      }
    },
    model: function model(params, transition) {
      if (params.shibbolethTest && !params.errCode) {
        reply(null);
      } else if (params.errCode) {
        var errMessage = '';
        switch (params.errCode) {
          case '401':
            errMessage = this.get('intl').findTranslationByKey('loginPage.shibbolethError.401');
            break;
          case '500':
            errMessage = this.get('intl').findTranslationByKey('loginPage.shibbolethError.500');
            break;
          default:
            errMessage = this.get('intl').findTranslationByKey('loginPage.shibbolethError.generic', { error: params.errCode });
            break;
        }

        transition.abort();
        this.transitionTo('login', { queryParams: { errorMsg: errMessage } });
      } else {
        if (this.get('shibbolethAuth.hasToken')) {
          this.transitionTo('authenticated');
        } else {
          this.transitionTo('login');
        }
      }

      function reply(err) {
        try {
          window.opener.window.onShibbolethTest(err);
          _ember['default'].run.later(function () {
            window.close();
          }, 250);
        } catch (e) {
          window.close();
        }
      }
    },
    setupController: function setupController(controller) {
      controller.set('settings', _ember['default'].inject.service('settings'));
    }

  });
});