define('ui/components/form-engine-opts/component', ['exports', 'ember', 'ui/utils/constants'], function (exports, _ember, _uiUtilsConstants) {
  exports['default'] = _ember['default'].Component.extend({
    machine: null,
    settings: _ember['default'].inject.service(),
    intl: _ember['default'].inject.service(),
    showEngineUrl: null,

    didReceiveAttrs: function didReceiveAttrs() {
      if (this.get('machine.engineInstallUrl') === undefined && this.get('showEngineUrl')) {
        this.set('machine.engineInstallUrl', this.get('settings.' + _uiUtilsConstants['default'].SETTING.ENGINE_URL) || '');
      }
    },

    engineUrlChoices: (function () {
      var def = this.get('settings.' + _uiUtilsConstants['default'].SETTING.ENGINE_URL);
      var out = [{ label: this.get('intl').t('formEngineOpts.engineInstallUrl.recommended'), value: def }, { label: 'v1.12.x', value: 'https://releases.rancher.com/install-docker/1.12.sh' }, { label: 'v1.13.x', value: 'https://releases.rancher.com/install-docker/1.13.sh' }, { label: 'v17.03.x', value: 'https://releases.rancher.com/install-docker/17.03.sh' }, { label: 'v17.06.x', value: 'https://releases.rancher.com/install-docker/17.06.sh' }, { label: 'v17.09.x', value: 'https://releases.rancher.com/install-docker/17.09.sh' }, { label: 'v17.12.x', value: 'https://releases.rancher.com/install-docker/17.12.sh' }, { label: this.get('intl').t('formEngineOpts.engineInstallUrl.latest'), value: 'https://get.docker.com' }];

      return out;
    }).property('intl._locale', 'settings.' + _uiUtilsConstants['default'].SETTING.ENGINE_URL),

    actions: {
      setEngine: function setEngine(url) {
        this.set('machine.engineInstallUrl', url);
      }
    }
  });
});